import React from 'react';
import PropTypes from 'prop-types';

import Pin from '../svg/pin.svg';

import styles from './Marker.module.scss';

const phoneFormat = phone => {
  if (phone.length === 11) {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
  if (phone.length === 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

  return phone;
};

class Marker extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      open: false,
    };
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    let size;
    if (this.props.zoom > 15) {
      size = 256;
    } else if (this.props.zoom > 13) {
      size = 128;
    } else if (this.props.zoom > 10) {
      size = 64;
    } else if (this.props.zoom > 8) {
      size = 32;
    } else {
      size = 16;
    }

    const style = {
      height: size,
      marginLeft: -(size / 2),
      marginTop: -(size / 2),
      width: size,
    };

    let box = null;
    if (this.state.open) {
      box = (
        <div className={styles.box}>
          <div className={styles.close} onClick={this.toggle}>X</div>
          <Pin />
          <strong>{this.props.data.name}</strong><br />
          {this.props.data.phone ? phoneFormat(this.props.data.phone) + ' / ' : null}
          {this.props.data.city}
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className={styles.marker} style={style} />
        {box}
      </React.Fragment>
    );
  }
}

Marker.propTypes = {
  data: PropTypes.object,
  zoom: PropTypes.number,
};

export default Marker;
