import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {Form} from '@evag/ec-react';
import Comite from 'src/components/forms/Comite';
import Interesses from 'src/components/forms/Interesses';
import Feedback from 'src/components/forms/Feedback';

import Button from './Button';

import styles from './CampaignForm.module.scss';

const CampaignForm = ({values, onChange, onBlur, setFieldValue}) => {
  const props = (name) => ({name, value: values[name], onChange, onBlur});
  return (
    <Form
      forms={[
        {
          ...Comite,
          channelId: '10a4bd8e-491b-44ca-9b7a-360d8066e937',
        },
        {
          ...Interesses,
          channelId: '332fda98-cdda-47d7-88d5-6da27b12b932',
        },
        {
          ...Feedback,
          title: 'Bolsonaro Nunca Mais!',
          url: typeof window !== 'undefined' && window.location.href,
          text: 'Muito obrigada! Agora ajude a espalhar essa campanha compartilhando nas redes sociais!',
        },
      ]}
    />
  );
};

CampaignForm.initialValues = {
  name: '',
  email: '',
  phone: '',
  uf: '',
  city: '',
  subscribe: false,
};

CampaignForm.validationSchema = Yup.object().shape({
  name: Yup.string().required('O campo Nome é obrigatório.'),
  email: Yup.string()
    .email('Digite um e-mail válido.')
    .required('O campo E-mail é obrigatório.'),
  phone: Yup.string(),
  uf: Yup.string().required('O campo Estado é obrigatório.'),
  city: Yup.string().required('O campo Cidade é obrigatório.'),
  subscribe: Yup.bool(),
});

CampaignForm.propTypes = {
  values: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

CampaignForm.captcha = true;
CampaignForm.element = CampaignForm;

export default CampaignForm;
