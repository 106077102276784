import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Button from './Button';
import CountSignatures from './CountSignatures';

import styles from './Hero.module.scss';

const Hero = ({background, logo, description, channel, title}) => (
  <div className={styles.container}>
    {/* <Img
      className={styles.image}
      fluid={background}
      style={{position: false}}
    /> */}
    <div className={styles.overlay}>
      <Img
        className={styles.logo}
        alt="Bolsonaro Nunca Mais!"
        fluid={logo}
        // style={{position: false}}
      />
    </div>
  </div>
);

Hero.propTypes = {
  background: PropTypes.object,
  logo: PropTypes.object,
  description: PropTypes.string,
  channel: PropTypes.string,
  title: PropTypes.string,
};

export default Hero;
