import React from 'react';
import PropTypes from 'prop-types';

import styles from './Counter.module.scss';

const numberFormat = (number) => (
  new Intl.NumberFormat('pt-BR').format(number)
);

const Counter = ({image, number, label}) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrap}>
        {image
          ? <div className={styles.image}>{image}</div>
          : null}
        <div className={styles.count}>
          <span className={styles.number}>{numberFormat(number)}</span>
          {' '}
          <span className={styles.label}>{label}</span>
        </div>
      </div>
    </div>
  );
};

Counter.defaultProps = {
  image: null,
  number: 0,
  label: '',
};

Counter.propTypes = {
  image: PropTypes.node,
  number: PropTypes.number,
  label: PropTypes.string,
};

export default Counter;
