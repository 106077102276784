import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  InputText,
  InputCheckbox,
  InputLocation,
  Select,
  handleCEPChange,
} from '@evag/ec-react';
import '@evag/ec-react/dist/main.css';

import colors from 'src/components/colors';
import Button from 'src/components/Button';
import s from './Basic.module.scss';
import is from './inputs.module.scss';

const subscribeText = 'Marque aqui para receber novidades do Prof. Josemar';

const Comite = ({title, text, values, onChange, onBlur, setFieldValue}) => {
  const [disabledLogradouro, setDisabledLogradouro] = useState(false);
  const refNumero = useRef(null);
  const refLogradouro = useRef(null);

  const props = (name) => ({name, value: values[name], onChange, onBlur});
  return (
    <div className={s.sidebar}>
      <InputText
        className={is.text}
        placeholder="Nome completo"
        {...props('name')}
      />
      <InputText
        className={is.text}
        cleave={{blocks: [2, 2, 4], delimiter: '/', numericOnly: true}}
        placeholder="Data de nascimento"
        type="tel"
        {...props('dob')}
      />
      <Select
        style={{width: '100%', height: '40px', padding: '7px'}}
        options={[
          'feminino',
          'masculino',
          'não binárie',
          'prefiro não declarar',
        ]}
        placeholder="Gênero"
        onChange={onChange}
        {...props('genero')}
      />
      <InputText
        className={is.text}
        placeholder="E-mail"
        type="email"
        {...props('email')}
      />
      <InputText
        className={is.text}
        cleave={{
          blocks: [0, 2, 9],
          delimiters: ['(', ') '],
          numericOnly: true,
        }}
        placeholder="WhatsApp"
        type="tel"
        {...props('phone')}
      />
      <p>
        Seu endereço será utilizado apenas para envios de materiais por parte da
        Equipe do Prof. Josemar (ou equipes de apoio) e estará marcado no mapa do site com
        um raio de aproximação, sem informar seus dados, garantindo assim a sua
        segurança.
      </p>
      <InputLocation
        className={is.select}
        names={['uf', 'city']}
        firstUf="SP"
        {...{values, onChange, onBlur, setFieldValue}}
      />
      <InputText
        className={is.text}
        cleave={{blocks: [5, 3], delimiter: '-', numericOnly: true}}
        placeholder="CEP"
        type="tel"
        {...{
          ...props('endCEP'),
          onChange: handleCEPChange(
            setDisabledLogradouro,
            setFieldValue,
            refNumero,
            refLogradouro,
            onChange
          ),
        }}
      />
      <InputText
        className={is.text}
        placeholder="Logradouro"
        disabled={disabledLogradouro}
        ref={refLogradouro}
        width="75%"
        {...props('endLogradouro')}
      />
      <InputText
        className={is.text}
        placeholder="Número"
        ref={refNumero}
        style={{marginLeft: '7%', width: '93%'}}
        type="tel"
        width="25%"
        {...props('endNumero')}
      />
      <InputText
        className={is.text}
        placeholder="Complemento"
        {...props('endComplemento')}
      />
      <InputText
        className={is.text}
        placeholder="Bairro"
        {...props('district')}
      />
      <InputCheckbox
        className={is.checkbox + ' ' + s.checkbox}
        label={subscribeText}
        {...props('subscribe')}
      />
      <input type="hidden" name="redirect" value={values.redirect} />
      <Button bg={colors.white} fg={colors.black} fontSize="1.2rem" type="submit">
        Cadastre o seu comitê!
      </Button>
      <p className={s.privacy}>
        <a
          href="https://profjosemarpsol.com.br/politica-de-privacidade"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de privacidade
        </a>
      </p>
    </div>
  );
};

Comite.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  values: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

Comite.initialValues = {
  name: '',
  email: '',
  phone: '',
  uf: '',
  city: '',
  genero: '',
  dob: '',
  endCEP: '',
  endLogradouro: '',
  endNumero: '',
  endComplemento: '',
  district: '',
  subscribe: false,
  redirect: 'https://vivireis.com.br/ok/',
};

Comite.validationSchema = Yup.object().shape({
  name: Yup.string().required('O campo Nome é obrigatório.'),
  genero: Yup.string(),
  dob: Yup.string().matches(/^..\/..\/....$/, 'Digite uma data válida.'),
  email: Yup.string()
    .email('Digite um e-mail válido.')
    .required('O campo E-mail é obrigatório.'),
  phone: Yup.string(),
  uf: Yup.string().required('O campo Estado é obrigatório.'),
  city: Yup.string().required('O campo Cidade é obrigatório.'),
  endCEP: Yup.string().required('O campo CEP é obrigatório.'),
  endLogradouro: Yup.string().required('O campo Logradouro é obrigatório.'),
  endNumero: Yup.string(),
  endComplemento: Yup.string(),
  district: Yup.string(),
  subscribe: Yup.bool(),
});

Comite.onSuccess = ({jump, values}) => {
  document.getElementById('cadastrar').scrollIntoView();
  return jump(1, values);
};
Comite.captcha = true;
Comite.element = Comite;
export default Comite;
