import React from 'react';
import PropTypes from 'prop-types';

import ShareButtons from 'src/components/ShareButtons';
import s from './Feedback.module.scss';

const Feedback = ({title, url, text}) => (
  <>
    <p style={{margin: '20px 0'}}>{text}</p>
    {url ? (
      <div className={s.social}>
        <ShareButtons title={title} url={url} />
      </div>
    ) : null}
  </>
);

Feedback.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  text: PropTypes.string,
};

Feedback.element = Feedback;
export default Feedback;
