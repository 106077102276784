import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';

import {Form} from '@evag/ec-react';

import '@evag/ec-react/dist/main.css';

import colors from 'src/components/colors';
import Button from 'src/components/Button';
import CampaignFeedback from '../components/CampaignFeedback';
import CampaignForm from '../components/CampaignForm';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Map from '../components/Map';

export default function render({data}) {
  CampaignForm.channelId = data.site.meta.channel;

  return (
    <Layout>
      <SEO title={data.site.meta.title} />
      <Hero
        background={data.hero.childImageSharp.fluid}
        logo={data.logo.childImageSharp.fluid}
        title={data.site.meta.title}
        description={data.site.meta.description}
        channel={data.site.meta.channel}
      />

       <div className='background-text'>
       <div className="wrap text-section">
        <section>
          <Button to="#cadastrar" bg={colors.orange}>
            Cadastre o seu comitê!
          </Button>
          <p>
            Ou derrotamos Bolsonaro em 2022 ou Bolsonaro derrota o Brasil. 
            Nosso meio ambiente, a educação, a saúde, a cultura, as mulheres, 
            negros, indígenas e LGBTs estão em risco enquanto o genocida nos 
            governar.
          </p>
          <p>
            O mandato do vereador de São Gonçalo/RJ Prof. Josemar (PSOL) está 
            engajado nessa luta e quer levar para todo o estado do Rio de Janeiro 
            os comitês pelo fora Bolsonaro. Qualquer uma ou qualquer um pode participar. 
            Cadastrando-se, você fará parte de uma rede para derrotar Bolsonaro, 
            nas ruas e nas eleições.
          </p>
          <p>
           Com seu comitê doméstico, você receberá em casa materiais e dicas para multiplicar 
            a campanha #BolsonaroNuncaMais. E poderá ser uma referência para sua vizinhança, bairro, 
            cidade, família e amigos sobre como derrotar o genocida. Nossa mobilização ocorrerá nas 
            ruas e também nas redes sociais.
          </p>
          <p>
            Precisamos de você. Engaje-se. Se você também for #BolsonaroNuncaMais, 
            cadastre agora o seu comitê doméstico.
          </p>
        </section>
      </div>
      </div>

      <div id="cadastrar" className="signatures">
        <div className="wrap">
          <h2 className="signatures-title" align="center">Cadastre já o seu!</h2>

          <Form
            forms={[
              CampaignForm,
              {
                ...CampaignFeedback,
                title: data.site.meta.title,
                url: data.site.meta.url,
              },
            ]}
          />

          <Img
            className="pessoas"
            alt="Pessoas pelo Bolsonaro Nunca Mais."
            fluid={data.pessoas.childImageSharp.fluid}
          />
        </div>
      </div>
      <Map />
      {/* <iframe
        width="100%"
        height="500"
        src="https://www.youtube-nocookie.com/embed/UULu2t9_dr8"
        title="Bolsonaro Nunca Mais!"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <div className="wrap text-section">
        <Button to="#cadastrar" bg={colors.purple}>
          Cadastre o seu comitê!
        </Button>

        <section id="materiais">
          <h2>Materiais</h2>
          <a
            href="https://drive.google.com/drive/folders/1k9G8dQy4Yejp0iinIFAA-ApR_jHt3M4_"
            target="_blank"
            rel="noopener noreferrer"
          >
            Para imprimir
          </a>
          <a
            href="https://drive.google.com/drive/folders/1dSmjO0xYo9oMMMuriJuME4ahtxAwoP1k"
            target="_blank"
            rel="noopener noreferrer"
          >
            Para espalhar nas redes sociais
          </a>
        </section>
          </div> */}
      <footer>
        <div className="wrap">
          <p>
            Site e campanha desenvolvidos e administrados por{' '}
            <a
              href="https://profjosemarpsol.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              Prof. Josemar
            </a>{' '}
            e sua equipe.
          </p>
          <p>
            Para saber mais:
            <br />
            E-mail:{' '}
            <a href="mailto:contato@profjosemarpsol.com.br">
              contato@profjosemarpsol.com.br
            </a>
            {/* <br />
            WhatsApp: <a href="http://wa.me/5511964625150">(11) 96462-5150</a> */}
          </p>
        </div>
      </footer>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: {eq: "fundo_bolsonaronuncamais.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    logo: file(relativePath: {eq: "capa site bolsonaro nunca mais.png"}) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    pessoas: file(relativePath: {eq: "pessoas.png"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
