import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {InputCheckbox, Select} from '@evag/ec-react';

import colors from 'src/components/colors';
import s from './Basic.module.scss';
import Button from 'src/components/Button';
import is from './inputs.module.scss';

const Interesses = ({values, onChange, onBlur, setFieldValue}) => {
  const props = (name) => ({name, value: values[name], onChange, onBlur});

  return (
    <>
      <p style={{margin: '2rem 0 0'}}>
        Escolha as pautas pelas quais você tem maior interesse, pelo menos 1.
      </p>
      <p style={{fontWeight: 'bold', margin: '0.5rem 0'}}>
        Se estiver no computador, segure a tecla CTRL enquanto clica para
        escolher mais de uma opção.
      </p>
      <Select
        style={{width: '100%', padding: '7px'}}
        size="15"
        options={[
          'Mulheres',
          'Educação',
          'Saúde',
          'Cultura',
          'LGBTQI+',
          'Meio Ambiente',
          'Negritude',
          'Maternidade',
          'Juventude',
          'Pessoas com deficiência',
          'Segurança Alimentar',
          'Ciência e Tecnologia',
          'Política de drogas',
          'Emprego e Renda',
          'Mobilidade Urbana'
        ]}
        onChange={onChange}
        {...props('interesses')}
        multiple
      />

      <InputCheckbox
        className={is.checkbox + ' ' + s.checkbox}
        label="Quero participar dos grupos de Whatsapp temáticos"
        {...props('grupoWhatsapp')}
      />

      <input type="hidden" name="redirect" value={values.redirect} />
      <Button type="submit" bg={colors.white} fg={colors.black} fontSize="20px">
        Confirmar
      </Button>
    </>
  );
};

Interesses.propTypes = {
  values: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

Interesses.initialValues = {
  interesses: '',
  grupoWhatsapp: '',
  redirect: 'https://samiabomfim.com.br/ok/',
};

Interesses.validationSchema = Yup.object().shape({
  interesses: Yup.string(),
  grupoWhatsapp: Yup.bool(),
});

Interesses.element = Interesses;
Interesses.captcha = true;
export default Interesses;
