// Keep colors in sync with _common.scss.
const colors = {
  yellow: '#ffdc02',
  lightYellow: '#ffdc02',
  red: '#b31b12',
  purple: '#6e4db6',
  black: '#222',
  gray: '#777',
  lightestGray: '#f2f2f2',
  white: '#fff',
  orange: '#e2551d',
  pink: '#ec4b8e'
};

export default colors;
