import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

import {count} from '@evag/ec-react';
import Counter from './Counter';

const delay = 40;
const parts = 23;

class SignaturesCounter extends React.Component {
  constructor(props) {
    super(props);

    this.setCount = this.setCount.bind(this);

    this.state = {
      count: 0,
      error: false,
      loading: true,
    };
  }

  setCount(number) {
    for (let i = 0; i < parts; i++) {
      window.setTimeout(() => {
        this.setState({
          count: Math.floor(((i + 1) * number) / parts),
        });
      }, (i + 1) * delay);
    }
  }

  componentDidMount() {
    count(this.props.channelId)
      .then((res) => {
        this.setCount(res.data.count);
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          error: true,
        });
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    if (this.state.loading) {
      if (this.props.loader) {
        return (
          <div style={{display: 'inline-block'}}>
            <Loader type="BallTriangle" color="#fff" height="32" width="32" />
          </div>
        );
      }

      return (
        <Counter image={this.props.image} label={this.props.label} number={0} />
      );
    }

    if (this.state.error) {
      return null;
    }

    return (
      <Counter
        image={this.props.image}
        label={this.props.label}
        number={this.state.count}
      />
    );
  }
}

SignaturesCounter.defaultProps = {
  image: null,
  label: 'assinaturas',
  loader: false,
};

SignaturesCounter.propTypes = {
  channelId: PropTypes.string.isRequired,
  image: PropTypes.node,
  label: PropTypes.string,
  loader: PropTypes.bool,
};

export default SignaturesCounter;
