import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import {find} from '@evag/ec-react';

import Marker from './Marker';
import Counter from './Counter';
import SignaturesCounter from './SignaturesCounter';
import House from '../svg/house.svg';
import Pin from '../svg/pin.svg';

import styles from './Map.module.scss';

const mapsApiKey = 'AIzaSyDj4IZbv-CO99hjwdEnFCkSLk9vgUpFM80';
const channelId = '10a4bd8e-491b-44ca-9b7a-360d8066e937';

// TODO: Map border.

// class SearchBox extends React.Component {
//   constructor(props) {
//     super(props);
//     this.onPlacesChanged = this.onPlacesChanged.bind(this);
//   }

//   componentDidMount() {
//     const {map, mapApi} = this.props;
//     this.searchBox = new mapApi.places.SearchBox(this.searchInput);
//     this.searchBox.addListener('places_changed', this.onPlacesChanged);
//     this.searchBox.bindTo('bounds', map);
//   }

//   componentWillUnmount() {
//     const {mapApi} = this.props;
//     mapApi.event.clearInstanceListeners(this.searchInput);
//   }

//   onPlacesChanged() {
//     const {map} = this.props;
//     const selected = this.searchBox.getPlaces();
//     console.log(selected);
//     const {0: place} = selected;
//     if (!place.geometry) {
//       return;
//     }

//     if (place.geometry.viewport) {
//       map.fitBounds(place.geometry.viewport);
//     } else {
//       map.setCenter(place.geometry.location);
//       map.setZoom(17);
//     }

//     this.searchInput.blur();
//   }

//   render() {
//     return (
//       <input
//         className={inputStyles.text}
//         ref={(ref) => {
//           this.searchInput = ref;
//         }}
//         placeholder="Procure seu endereço"
//       />
//     );
//   }
// }

// SearchBox.propTypes = {
//   mapApi: PropTypes.object,
//   map: PropTypes.object,
// };

class CampaignPrevidencia extends React.Component {
  constructor(props) {
    super(props);

    this.apiHasLoaded = this.apiHasLoaded.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      elements: [],
      cities: 0,
      mapApiLoaded: false,
      mapApi: null,
      mapInstance: null,
      zoom: 6,
    };
  }

  componentDidMount() {
    find(channelId).then((res) => {
      const r = res.data.results;
      const cities = {};
      for (let i = 0; i < r.length; i++) {
        cities[r[i].city] = true;
      }
      this.setState({
        elements: r,
        cities: Object.keys(cities).length,
      });
    });
  }

  apiHasLoaded(map, maps) {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });
  }

  onChange({zoom}) {
    this.setState({
      zoom,
    });
  }

  render() {
    return (
      <section className={styles.container + ` ${styles.full}`}>
        <div className="wrap">
          <div className={styles.map}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: mapsApiKey,
                libraries: ['places', 'geometry'],
              }}
              defaultCenter={{lat: -22.82694, lng: -43.0894}}
              defaultZoom={6}
              onChange={this.onChange}
              options={{disableDefaultUI: true, maxZoom: 16, minZoom: 5}}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({map, maps}) => this.apiHasLoaded(map, maps)}
            >
              {this.state.elements
                .filter((e) => e.lat && e.lng)
                .map((e, i) => (
                  <Marker
                    data={e}
                    key={i}
                    lat={e.lat}
                    lng={e.lng}
                    zoom={this.state.zoom}
                  />
                ))}
            </GoogleMapReact>
          </div>

          <div className={styles.text}>
            <div className={styles.counters}>
              {/* <SignaturesCounter channelId={channelId} label="assinaturas" /> */}
              <Counter
                image={<House className={styles.icon} />}
                number={this.state.elements.length}
                label="comitês"
              />
              <Counter
                image={<Pin className={styles.icon} />}
                number={this.state.cities}
                label="cidades"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

CampaignPrevidencia.defaultProps = {
  mainPage: false,
};

CampaignPrevidencia.propTypes = {
  mainPage: PropTypes.bool,
};

export default CampaignPrevidencia;
